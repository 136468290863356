import * as React from "react"
import { useState } from "react"
import { graphql, Link } from "gatsby"
import { AnimatePresence } from "framer-motion"

import "./about/about/about.scss"

import Layout from "../components/Layout"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout"
import DoubleBlockHead from "../components/offers/DoubleBlockHead/DoubleBlockHead"
import TextGlassCards from "../components/cards/TextGlassCard"
import Title from "../components/Title"
import TextGrayCards from "../components/cards/TextGrayCards"
import TrialLesson from "../components/offers/TrialLesson"
import TeacherPopup from "../components/popups/TeacherPopup"
import VideoPopup from "../components/popups/VideoPopup"
import FormPopup from "../components/popups/FormPopup"
import GalleryPopup from "../components/popups/GalleryPopup"
import { MENU_LINKS } from "../consts/MenuLinks"
import { STRAPI_API } from "../consts/api"
import VideoBox from "../components/VideoBox"
import SliderImages from "../components/sliders/SliderImages"
import TeachersMain from "../components/TeachersMain"
import StudentReviews from "../components/cards/StudentReviews"
import HelpChoosing from "../components/offers/HelpChoosing"
import {SEO} from "../components/seo";

const About = ({ data, location }) => {
  const { allStrapiTeacher, strapiAboutPage, strapiStudentReview } = data

  const [popUpVideo, setPopUpVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("")

  const [popUpGallery, setPopUpGallery] = useState(false)
  const [galleryElem, setGalleryElem] = useState(false)

  const [formPopup, setFormPopup] = useState(false)

  const [teacherPopup, setTeacherPopup] = useState(false)
  const [currentTeacher, setCurrentTeacher] = useState({})

  const mapTextCardPromotion = data => {
    return [{ title: data.item1 }, { title: data.item2 }, { title: data.item3 }]
  }

  const mapEventsTopData = data => {
    return [
      { title: data.topCard1.title, text: data.topCard1.subtitle },
      { title: data.topCard2.title, text: data.topCard2.subtitle },
    ]
  }

  const mapEventsBottomData = data => {
    return [
      { title: data.botomCard1.title, text: data.botomCard1.subtitle },
      { title: data.botomCard2.title, text: data.botomCard2.subtitle },
      { title: data.botomCard3.title, text: data.botomCard3.subtitle },
    ]
  }

  const mapHowTextCard = data => {
    return Object.values(data).map(item => {
      return {
        title: item.title,
        text: item.subtitle,
      }
    })
  }

  const mapValuesCards = data => {
    return [
      { title: data.top1 },
      { title: data.top2 },
      { title: data.top3 },
      { title: data.bottom1 },
      { title: data.bottom2 },
    ]
  }

  const mapIntenseCourse = data => {
    const mapCourseLinks = data =>
      data.map(item => {
        return {
          title: item.title,
          link: MENU_LINKS.course + `${item.slug}/`,
        }
      })

    return Object.values(data)
      .filter(e => typeof e !== "string")
      .map(item => {
        return {
          title: item.title,
          text: item.subtitle,
          links: mapCourseLinks(item?.course_pages),
        }
      })
  }

  return (
    <div>
      <SEO title={strapiAboutPage?.titleDesc?.pageTitle} description={strapiAboutPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="aboutPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          {
            // MAIN OFFER
            strapiAboutPage?.mainOffer && (
              <DoubleBlockHead
                setFormPopup={setFormPopup}
                data={strapiAboutPage?.mainOffer}
              />
            )
          }

          {strapiAboutPage?.t1 &&
            strapiAboutPage?.t2 &&
            strapiAboutPage?.t3 &&
            strapiAboutPage?.miniText && (
              <div className="startTextBlock m140">
                <h2 className="">{strapiAboutPage.t1}</h2>
                <h3 className="">{strapiAboutPage.t2}</h3>
                <h3 className="">{strapiAboutPage.t3}</h3>
                <p className="">{strapiAboutPage.miniText}</p>
              </div>
            )}

          {
            // MISSION
            strapiAboutPage?.mission && (
              <div className="m140">
                <Title
                  value={[
                    ["dark", "Главная  "],
                    ["red", "миссия "],
                  ]}
                />

                <TextGlassCards
                  classAdd={"textCard--one m60_40 ourMission"}
                  data={[{ title: strapiAboutPage.mission }]}
                />
              </div>
            )
          }

          {
            // TEXT CARDS VALUES
            strapiAboutPage?.values && (
              <div className="m140">
                <Title
                  value={[
                    ["red", "Ценности  "],
                    ["dark", "школы"],
                  ]}
                />

                <TextGrayCards
                  data={mapValuesCards(strapiAboutPage.values)}
                  view={"check"}
                  classAdd="textCard--three-two m60_40"
                />
              </div>
            )
          }

          {
            // OFFER TRIAL LESSON BUTTON
            strapiAboutPage?.offer && (
              <div className="trialLesson m140">
                <TrialLesson
                  data={strapiAboutPage.offer}
                  setFormPopup={setFormPopup}
                />
              </div>
            )
          }

          {
            // OUR ACTIVITES
            strapiAboutPage?.activites && (
              <div className="m140">
                <Title
                  value={[
                    ["dark", "Наши "],
                    ["red", "направления "],
                  ]}
                />

                <TextGlassCards
                  classAdd={"textCard--one m60_40 textCardsActivites"}
                  data={mapIntenseCourse(strapiAboutPage.activites)}
                />
              </div>
            )
          }

          {
            // INTENSE COURSE
            strapiAboutPage?.intense && (
              <div className="m140">
                <Title
                  value={[
                    ["red", "Интенсивные "],
                    ["dark", "курсы"],
                  ]}
                  subTitle="Позволяют освоить навыки за короткий срок, подходят тем, кто приезжает на обучение из других городов"
                />

                <TextGlassCards
                  classAdd={"textCard--two m60_40"}
                  data={mapIntenseCourse(strapiAboutPage.intense)}
                />
              </div>
            )
          }

          {
            // HOW LEARNING TEXT CARDS
            strapiAboutPage?.howLearning && (
              <div className="m140">
                <Title
                  value={[
                    ["red", "Как  "],
                    ["dark", "проходит обучение"],
                  ]}
                />

                <TextGrayCards
                  data={mapHowTextCard(strapiAboutPage.howLearning)}
                  view={"numbering"}
                  classAdd="m60_40"
                />
              </div>
            )
          }

          {
            // VIDEO BOX CARD
            strapiAboutPage?.promoVideo?.url && (
              <div className="m60_40">
                <VideoBox
                  videSrc={STRAPI_API + strapiAboutPage.promoVideo.url}
                  coverImg={
                    strapiAboutPage?.promoVideoCover?.url
                      ? STRAPI_API + strapiAboutPage.promoVideoCover.url
                      : null
                  }
                  setVideoSrc={setVideoSrc}
                  setPopUpVideo={setPopUpVideo}
                />
              </div>
            )
          }

          {
            // EVENTS TEXT CARDS BLOCK
            strapiAboutPage?.events && (
              <div className={"eventsTextCardsBlock m140"}>
                <Title
                  value={[
                    ["dark", "Мы проводим "],
                    ["red", "мероприятия"],
                  ]}
                  valueLink={{
                    link: MENU_LINKS.teachers,
                    valueLink: "Фотоотчеты с мероприятий",
                  }}
                  subTitle="Pioneer DJ School — специализированная площадка для выступлений, лекций, воркшопов и других ивентов"
                />

                <Title
                  value={[["dark", "Два формата мероприятий "]]}
                  classAdd={"eventsTextCardsTitle"}
                />

                <TextGrayCards
                  data={mapEventsTopData(strapiAboutPage.events)}
                  classAdd={"textCard--two textCard--simple"}
                />

                <Title
                  value={[["dark", "Какие это мероприятия? "]]}
                  classAdd={"eventsTextCardsTitle"}
                />

                <TextGlassCards
                  classAdd={"textCard--three eventsTextCards"}
                  data={mapEventsBottomData(strapiAboutPage.events)}
                />
              </div>
            )
          }

          {
            // PHOTO SLIDER
            strapiAboutPage?.sliderImg && (
              <div>
                <SliderImages
                  data={strapiAboutPage.sliderImg}
                  positionCenter={false}
                  setOpen={setPopUpGallery}
                  open={popUpGallery}
                  setGalleryElem={setGalleryElem}
                />

                <div className="title__link--mobile">
                  <Link className="title__link" to={MENU_LINKS.photo}>
                    Фотоотчеты с мероприятий
                  </Link>
                </div>
              </div>
            )
          }

          {
            // TEACHERS
            allStrapiTeacher?.edges && (
              <div className="teachersMain m140">
                <Title
                  value={[
                    ["dark", "Наши"],
                    ["red", " преподаватели"],
                  ]}
                  valueLink={{
                    link: MENU_LINKS.teachers,
                    valueLink: "Все преподаватели",
                  }}
                  classAdd="teachersMain--title"
                />

                <TeachersMain
                  data={allStrapiTeacher.edges}
                  setCurrentTeacher={setCurrentTeacher}
                  setTeacherPopup={setTeacherPopup}
                  classAdd={"m60_40"}
                />

                <div className="title__link--mobile">
                  <Link className="title__link" to={MENU_LINKS.teachers}>
                    Все преподаватели
                  </Link>
                </div>
              </div>
            )
          }

          {
            // STUDENTS PROMOTION TEXT CARDS
            strapiAboutPage?.promotionStudents && (
              <div className="studentPromotionTextCards m140">
                <Title
                  value={[
                    ["dark", "Выпускников "],
                    ["red", " ждёт помощь в продвижении"],
                  ]}
                  classAdd="studentReviews--title"
                  subTitle="По окончании курса тебя ждет бесплатное участие в системе по продвижению Pioneer DJ Family"
                />

                <Link className="title__link" to={MENU_LINKS.family}>
                  Подробнее о Pioneer DJ Family
                </Link>

                <div className="m60_40">
                  <TextGrayCards
                    data={mapTextCardPromotion(
                      strapiAboutPage.promotionStudents
                    )}
                    classAdd={"textCard--three textCard--bigText"}
                  />
                </div>
              </div>
            )
          }

          {
            // STUDENT REVIEWS
            strapiStudentReview?.StudentReview && (
              <div className="studentReviews m140">
                <Title
                  value={[
                    ["dark", "Что говорят"],
                    ["red", " студенты о школе"],
                  ]}
                  classAdd="studentReviews--title"
                />

                <StudentReviews
                  data={strapiStudentReview.StudentReview}
                  setVideoSrc={setVideoSrc}
                  setPopUpVideo={setPopUpVideo}
                  classAdd={"studentReview m60_40"}
                />
              </div>
            )
          }

          {
            // OFFER WITH FORM - CHOOSING HELP
            strapiAboutPage?.offer2 && (
              <div className="helpChoosing m140">
                <HelpChoosing data={strapiAboutPage?.offer2} />
              </div>
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {teacherPopup && (
          <TeacherPopup
            open={teacherPopup}
            setOpen={setTeacherPopup}
            currentTeacher={currentTeacher}
          />
        )}

        {popUpVideo && videoSrc && (
          <VideoPopup
            open={popUpVideo}
            setOpen={setPopUpVideo}
            videoSrc={videoSrc}
          />
        )}

        {popUpGallery && strapiAboutPage?.sliderImg?.data && (
          <GalleryPopup
            open={popUpGallery}
            setOpen={setPopUpGallery}
            galleryElem={galleryElem}
            data={data?.aboutPage?.data?.attributes?.sliderImg?.data}
          />
        )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiAboutPage {
      mainOffer {
        title
        subtitle
        button
      }
      t1
      t2
      t3
      miniText
      mission
      offer {
        title
        subtitle
        button
      }
      offer2 {
        title
        subtitle
        button
      }
      values {
        top3
        top2
        top1
        bottom1
        bottom2
      }
      activites {
        item1 {
          title
          subtitle
          course_pages {
            title
            slug
          }
        }
        item2 {
          title
          subtitle
          course_pages {
            title
            slug
          }
        }
        item3 {
          title
          subtitle
          course_pages {
            title
            slug
          }
        }
      }
      howLearning {
        s1 {
          title
          subtitle
        }
        s2 {
          title
          subtitle
        }
        s3 {
          title
          subtitle
        }
        s4 {
          title
          subtitle
        }
      }
      intense {
        item1 {
          title
          course_pages {
            strapi_id
            title
            slug
          }
        }
        item2 {
          title
          course_pages {
            title
            slug
            strapi_id
          }
        }
      }
      promoVideoCover {
        url
      }
      promoVideo {
        url
      }
      events {
        topCard2 {
          title
          subtitle
        }
        topCard1 {
          title
          subtitle
        }
        botomCard3 {
          title
          subtitle
        }
        botomCard2 {
          title
          subtitle
        }
        botomCard1 {
          title
          subtitle
        }
      }
      sliderImg {
        url
      }
      promotionStudents {
        item1
        item2
        item3
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
    allStrapiTeacher(sort: { fields: createdAt, order: DESC }, limit: 4) {
      edges {
        node {
          strapi_id
          djName
          name
          slug
          teacherCard {
            subtitle
            cover {
              url
            }
          }
          photo {
            url
          }
          mainDescription
          socialLinks {
            socials
            link
          }
        }
      }
    }
    strapiStudentReview {
      StudentReview {
        studentName
        coverImg {
          url
          alternativeText
        }
        video {
          url
          alternativeText
        }
      }
    }
  }
`

export default About
